import { FC } from 'react';

import { Content } from 'components/layout/Content';
import { Text } from 'components/text/Text';
import { Theme } from 'styles/theme';

interface Props {
  title: string;
  subtitle?: string;
  marginTop?: string;
  color?: keyof Theme['colors'];
  subtitleColor?: keyof Theme['colors'];
}
export const Title: FC<Props> = ({
  title,
  subtitle,
  color = 'text',
  marginTop = '5rem',
  subtitleColor = 'text',
}) => (
  <Content
    marginTop={marginTop}
    flexDirection="column"
    alignItems={{ _: 'flex-start', md: 'center' }}
  >
    <Text color={color} fontSize="hero25" fontWeight="bold" marginBottom="1rem">
      {title}
    </Text>
    <Text color={subtitleColor} fontSize="regular">
      {subtitle}
    </Text>
  </Content>
);
