export const Guarantee = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.61611 15.3153C6.83661 15.4331 7.11336 15.4323 7.33386 15.3146C13.3429 12.0866 13.7696 5.77983 13.7644 3.98658C13.7636 3.69108 13.5881 3.42858 13.3174 3.30933L7.27986 0.634832C7.08561 0.549332 6.86436 0.549332 6.67011 0.635582L0.674613 3.31008C0.408363 3.42933 0.234363 3.68733 0.230613 3.97908C0.205113 5.76333 0.564363 12.0836 6.61611 15.3153ZM4.50561 6.71958L6.22536 8.43933L9.44511 5.21958L10.5056 6.28008L6.22536 10.5603L3.44511 7.78008L4.50561 6.71958Z"
      fill="#808080"
    />
  </svg>
);
