import styled from 'styled-components';
import {
  alignContent,
  AlignContentProps,
  alignItems,
  AlignItemsProps,
  compose,
  grid,
  gridAutoFlow,
  GridAutoFlowProps,
  GridProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  justifyContent,
  JustifyContentProps,
  justifyItems,
  JustifyItemsProps,
} from 'styled-system';

import { Theme } from 'styles/theme';

import { Box } from './Box';

interface Props<T>
  extends GridProps<T>,
    AlignItemsProps<T>,
    AlignContentProps<T>,
    JustifyItemsProps<T>,
    GridAutoFlowProps<T>,
    JustifyContentProps<T>,
    GridTemplateColumnsProps<T> {}

const gridProps = compose(
  grid,
  alignItems,
  alignContent,
  justifyItems,
  gridAutoFlow,
  justifyContent,
  gridTemplateColumns,
);

export const Grid = styled(Box)<Props<Theme>>`
  display: grid;
  && {
    ${gridProps};
  }
`;
