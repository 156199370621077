import { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import {
  BackgroundColorProps,
  border,
  BorderProps,
  buttonStyle,
  ButtonStyleProps,
  compose,
  flex,
  FlexboxProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system,
  textAlign,
  TextAlignProps,
} from 'styled-system';

import { Loader1 } from 'components/other/Loader1';
import { Text } from 'components/text/Text';
import { Theme } from 'styles/theme';

const backgroundColor = system({
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
});

const buttonProps = compose(
  space,
  layout,
  buttonStyle,
  flex,
  border,
  shadow,
  textAlign,
  backgroundColor,
);

interface Props<T = Theme>
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    LayoutProps<T>,
    SpaceProps<T>,
    ShadowProps<T>,
    ButtonStyleProps<T>,
    FlexboxProps<T>,
    TextAlignProps<T>,
    BackgroundColorProps<T>,
    BorderProps<T> {
  text?: string;
  loading?: boolean;
  inverted?: boolean;
  color?: keyof Theme['colors'];
}

export const Button: FC<Props> = ({
  onClick,
  text,
  children,
  loading,
  disabled,
  color = 'white',
  ...rest
}) => (
  <Container disabled={disabled || loading} onClick={onClick} {...rest}>
    <Text color={color} fontWeight="black" fontSize="regular1125">
      {text || children}
    </Text>
    {loading && <Loader1 size="small" />}
  </Container>
);

const Container = styled.button<Props>`
  display: flex;
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  border-radius: ${p => p.theme.radii.eight};
  background-color: ${p =>
    p.inverted ? p.theme.colors.accent : p.theme.colors.main};

  &:hover {
    background-color: ${p =>
      p.inverted ? p.theme.colors.main : p.theme.colors.accent};
  }

  && {
    ${buttonProps}
  }
`;
