import styled from 'styled-components';

import { Text } from './Text';

export const Pill = styled(Text)`
  width: fit-content;
  text-align: center;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
`;
