import { IProduct } from 'types/payments';

export const prodProducts: { [id: string]: IProduct } = {
  price_1L0p9nAFqJNlrjlOhpOv5hay: {
    stripeId: 'price_1L0p9nAFqJNlrjlOhpOv5hay',
    period: 'monthly',
    priceStr: '$4.99',
    price: 4.99,
    currency: 'USD',
    bullets: [],
  },
  price_1L0p9fAFqJNlrjlOy2jsecyN: {
    stripeId: 'price_1L0p9fAFqJNlrjlOy2jsecyN',
    period: 'yearly',
    oldPriceStr: '$59.99',
    priceStr: '$19.99',
    price: 19.99,
    currency: 'USD',
    bullets: [],
  },
};

export const devProducts: { [id: string]: IProduct } = {
  price_1KxX6XAFqJNlrjlOCwgrLoJt: {
    stripeId: 'price_1KxX6XAFqJNlrjlOCwgrLoJt',
    period: 'monthly',
    priceStr: '$4.99',
    price: 4.99,
    currency: 'USD',
    bullets: [],
  },
  price_1KxXC9AFqJNlrjlOY64VeotG: {
    stripeId: 'price_1KxXC9AFqJNlrjlOY64VeotG',
    period: 'yearly',
    oldPriceStr: '$59.99',
    priceStr: '$19.99',
    price: 19.99,
    currency: 'USD',
    bullets: [],
  },
};

export const products =
  process.env.NODE_ENV === 'development' ? devProducts : prodProducts;
