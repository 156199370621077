import { UrlObject } from 'url';

import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import styled from 'styled-components';
import {
  BackgroundColorProps,
  border,
  BorderProps,
  compose,
  flex,
  FlexboxProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system,
  textAlign,
  TextAlignProps,
} from 'styled-system';

import { Text } from 'components/text/Text';
import { Theme } from 'styles/theme';

const backgroundColor = system({
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
});

const anchorProps = compose(
  space,
  layout,
  flex,
  border,
  shadow,
  textAlign,
  backgroundColor,
);

interface Props<T = Theme>
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<LinkProps, 'onMouseEnter' | 'onClick'>,
    LayoutProps<T>,
    SpaceProps<T>,
    ShadowProps<T>,
    FlexboxProps<T>,
    TextAlignProps<T>,
    BackgroundColorProps<T>,
    BorderProps<T> {
  as?: never;
  href: string | UrlObject;
  text?: string;
  loading?: boolean;
  inverted?: boolean;
  textDecoration?: 'line-through' | 'none' | 'blink' | string;
  children?: ReactNode;
  textTransform?:
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'none'
    | 'full-width'
    | 'full-size-kana'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset';
}

export const LinkButton: FC<Props> = ({
  href,
  text,
  children,
  passHref,
  replace,
  scroll,
  locale,
  shallow,
  prefetch,
  legacyBehavior,
  ...rest
}) => (
  <Link
    href={href}
    locale={locale}
    scroll={scroll}
    shallow={shallow}
    replace={replace}
    prefetch={prefetch}
    passHref={passHref}
    legacyBehavior={legacyBehavior}
  >
    <Container {...rest}>
      {children || (
        <Text fontWeight="black" fontSize="regular1125" color="white">
          {text}
        </Text>
      )}
    </Container>
  </Link>
);

const Container = styled.a<Props>`
  display: flex;
  border: none;
  cursor: pointer;
  min-height: 50px;
  position: relative;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  transition: background 0.2s;
  border-radius: ${p => p.theme.radii.eight};
  background-color: ${p =>
    p.inverted ? p.theme.colors.accent : p.theme.colors.main};

  &:hover {
    background-color: ${p =>
      p.inverted ? p.theme.colors.main : p.theme.colors.accent};
  }

  && {
    ${anchorProps}
  }
`;
