import { PaymentMethods } from 'components/icons/PaymentMethods';
import { SubscriptionItem } from 'components/items/SubscriptionItem';
import { Content } from 'components/layout/Content';
import { Flex } from 'components/layout/Flex';
import { Grid } from 'components/layout/Grid';
import { Section } from 'components/layout/Section';
import { products } from 'utils/products';

import { Title } from './Title';

export const Pricing = () => (
  <Section paddingBottom="2rem" flexDirection="column" backgroundColor="accent">
    <Title color="white" title="Supercharge your productivity" />
    <Content marginTop="2rem" flexDirection="column" alignItems="center">
      <Grid
        width="100%"
        gridGap="1.5rem"
        gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}
      >
        {Object.values(products).map(product => (
          <SubscriptionItem key={product.stripeId} {...product} />
        ))}
      </Grid>
      <Flex
        bg="white"
        marginTop="1rem"
        padding="0.5rem 1rem"
        justifyContent="center"
        alignItems="center"
        borderRadius="eight"
      >
        <PaymentMethods />
      </Flex>
    </Content>
  </Section>
);
