import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/buttons/Button';
import { LinkButton } from 'components/buttons/LinkButton';
import { Guarantee } from 'components/icons/Guarantee';
import { Flex } from 'components/layout/Flex';
import { Pill } from 'components/text/Pill';
import { Text } from 'components/text/Text';
import { actions } from 'state/actions';
import { AppState } from 'state/store';
import { IProduct } from 'types/payments';
import { isUserValid } from 'utils/user';

interface PartialState {
  user: AppState['user']['user'];
  loading: AppState['ui']['loading'];
}

export const SubscriptionItem: FC<IProduct> = product => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector<AppState, PartialState>(s => ({
    user: s.user.user,
    loading: s.ui.loading,
  }));

  // If user is not logged in, select product and redirect to auth
  const selectProduct = () => dispatch(actions.payment.setProduct(product));

  // If user is logged in, select product and go to checkout
  const initPayment = () => {
    dispatch(actions.payment.setProduct(product));
    setTimeout(() => dispatch(actions.payment.goToPayment()), 100);
  };

  const specialDeal = product.period === 'yearly';

  return (
    <Flex
      width="100%"
      borderRadius="ten"
      position="relative"
      overflow="visible"
      flexDirection="column"
      backgroundColor="white"
      border={specialDeal ? '5px solid yellow' : 'none'}
    >
      {specialDeal && (
        <Pill
          top="-18px"
          width="200px"
          color="text90"
          fontSize="small75"
          fontWeight="medium"
          position="absolute"
          left="calc(50% - 100px)"
          backgroundColor="secondaryAccent"
        >
          Limited time offer
        </Pill>
      )}
      <Flex padding="1rem" alignItems="center" flexDirection="column">
        <Flex my="1rem" width="100%">
          <Pill
            color="pillText"
            fontSize="small75"
            fontWeight="medium"
            backgroundColor="pillBg"
          >
            {product.period} <b>{specialDeal ? 'SAVE -67%' : ''}</b>
          </Pill>
        </Flex>
        <Flex alignItems="baseline">
          <Text fontSize="hero3" fontWeight="black">
            <span style={{ verticalAlign: 'super', fontSize: '1.5rem' }}>
              ${' '}
            </span>
            {product.price}
          </Text>
          <Text color="text40" marginLeft="0.5rem" fontSize="regular1125">
            {product.period === 'monthly' ? '/ month' : '/ year'}
          </Text>
        </Flex>
        <Text color="text30" marginTop="1rem" fontSize="small875">
          {product.oldPriceStr && (
            <span style={{ textDecoration: 'line-through', color: 'red' }}>
              {product.oldPriceStr}
            </span>
          )}
          {' ' + product.price} will be charged every{' '}
          {product.period === 'monthly' ? 'month' : 'year'}
        </Text>
      </Flex>
      <Flex
        height="100%"
        padding="1rem"
        flexDirection="column"
        backgroundColor="bgLight"
        borderBottomRightRadius="ten"
        borderBottomLeftRadius="ten"
      >
        <UlPro>
          <Li>Export to JSON</Li>
          <Li>Assign image labels</Li>
          <Li>Indefinite collections history</Li>
          <Li>Save images to Google Drive</Li>
          <Li>Downloads grouped by label, type, and size</Li>
          <Li>ImageVault - cloud-based hosting for your images</Li>
        </UlPro>
        {isUserValid(user) ? (
          <Button
            inverted
            paddingX="2rem"
            marginTop="2rem"
            loading={loading}
            text="Get Started"
            onClick={initPayment}
          />
        ) : (
          <LinkButton
            inverted
            href="/auth"
            paddingX="2rem"
            marginTop="2rem"
            text="Get Started"
            onClick={selectProduct}
          />
        )}
        <Flex
          marginTop="1rem"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Guarantee />
          <Text marginLeft="0.5rem" color="text60" fontSize="small875">
            30-DAY MONEY-BACK GUARANTEE
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const UlPro = styled.ul`
  list-style: none;
  margin-top: 1rem;
  margin-left: -0.8rem;

  li::before {
    content: '✅ ';
    margin-left: -1.2rem;
  }
`;

const Li = styled.li`
  font-weight: 400;
  margin: 0 0 1rem 0;
  font-family: ${p => p.theme.fonts.primary};
  font-size: ${p => p.theme.fontSizes.regular1};
`;
